import { Uploader } from '@/components/Widgets';
import { defineComponent } from 'vue';
import useFiles from '@/compositions/useFiles';
import { useToast } from 'vue-toastification';
import { Button, Table, Alert, ModalBox } from '@/components/UI';
import useMobile from '@/compositions/useMobile';
import useLocation from '@/compositions/useLocation';
export default defineComponent({
    name: 'FilesTab',
    components: {
        Uploader,
        Button,
        Table,
        Alert,
        ModalBox
    },
    setup() {
        return {
            ...useFiles(),
            ...useMobile(),
            ...useLocation(),
            toast: useToast()
        };
    },
    data: () => ({
        fields: [{
                key: 'filename',
                name: 'Filename',
                sortable: true
            }, {
                key: 'url',
                name: 'Url',
                sortable: true
            }, {
                key: 'preview',
                name: 'Preview',
                sortable: false
            }, {
                key: 'actions',
                name: 'Actions',
                sortable: false
            }],
        editMode: '',
        toDelete: '',
        confirmVisible: false
    }),
    computed: {
        uploadurl() {
            return `https://${this.currentLocation.apiurl}/api/files/upload`;
        },
        filetable() {
            const table = [];
            Object.values(this.files).forEach(file => {
                if (!file.filename.includes('user_display_image')) {
                    table.push({
                        filename: file.filename.replace('media/', ''),
                        id: file.filename.replace('media/', ''),
                        url: `https://${this.currentLocation.apiurl}/${file.filename}`
                    });
                }
            });
            return table;
        }
    },
    methods: {
        copyToClipboard(refname) {
            const ref = this.$refs[refname];
            if (ref instanceof HTMLInputElement) {
                ref.select();
                document.execCommand('copy');
                this.toast.success(`Url copied to clipboard`);
            }
        },
        showToastUpload({ code, filename }) {
            if (code === 200) {
                this.toast.success(`File ${filename} has been Uploaded`);
            }
            else if (code !== 0) {
                this.toast.error(`File ${filename} could not be uploaded, ${code}`);
            }
        },
        async deleteFile() {
            try {
                await this.$store.direct.dispatch.files.delete(this.toDelete);
                this.toast.success(`File ${this.toDelete} has been deleted`);
            }
            catch (error) {
                this.toast.error(`File ${this.toDelete} was not deleted ${error}`);
            }
            this.confirmVisible = false;
        },
        async rename(oldFilename, newFilename) {
            try {
                await this.$store.direct.dispatch.files.rename({ oldFilename, newFilename });
                this.toast.success(`File ${oldFilename} has been renamed`);
            }
            catch (error) {
                this.toast.error(`File ${oldFilename} was not renamed ${error}`);
            }
        },
        editClicked(filename) {
            if (this.editMode !== '') {
                this.rename(this.editMode, filename);
                this.editMode = '';
            }
            else {
                this.editMode = filename;
            }
        }
    }
});
