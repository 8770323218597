import store from '@/store';
import { computed, onMounted, onUnmounted } from 'vue';
const useFiles = () => {
    onMounted(async () => store.dispatch.files.subscribe());
    onUnmounted(async () => store.dispatch.files.unsubscribe());
    return {
        files: computed(() => store.state.files)
    };
};
export default useFiles;
